@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  src: url("../../ProximaNova-Regular.otf");
}

form {
  font-family: "ProximaNova";
}

.login-container > .logo {
  align-self: center;
}

.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-content: center;
  align-items: center;
}

.login-form__field > input,
.login-form__field > input:active,
.login-form__field > input:focus {
  width: 362px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-bottom: 20px;
  text-decoration: none;
  padding: 23px 10px;
  box-sizing: border-box;
}

.login-form__field > input::placeholder {
  color: #c4c4c4;
}

ul li a:hover,
ul li a:visited,
ul li a:link {
  color: #383789;
}

.icon {
  width: 32px;
  height: 32px;
}
